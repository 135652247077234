.dt-body {
  overflow-y: auto;
  flex-grow: 1; /* Ensure body takes up remaining space */
}

.rdt_TableCell {
  white-space: normal;
  word-break: break-word;
}


/* App.css */

/* Modal overlay style */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Modal content box */
.modalContent {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  min-width: 300px;
}

/* Input field style */
.inputField {
  margin-top: 10px;
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 10px;
}

/* Button to close modal */
.closeButton {
  justify-items: end;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* Button to open modal */
.openModalButton {
  background-color: #28a745;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}
