.form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    max-width: 1100px;
    margin: 0 auto;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .form-row {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }
  
  .field-container {
    display: flex;
    flex-direction: column;
   // align-items: center;
    width: 30%; /* Each field will take up 30% of the row width */
  }
  
  label {
    font-size: 14px;
    font-weight: bold;
    color: #333;
    margin-bottom: 8px;
  }
  
  .disabled-input {
    background-color: #e0e0e0;
    border: 1px solid #ccc;
    padding: 10px;
    font-size: 16px;
    width: 100%;
    border-radius: 8px;
    text-align: center;
    color: #777;
    cursor: not-allowed;
  }
  
  .result-textarea {
    width: 100%;
    min-height: 150px;
    padding: 10px;
    border: 1px solid #ccc;
    font-size: 16px;
    border-radius: 8px;
    color: #333;
    resize: vertical;
    box-sizing: border-box;
    transition: all 0.3s ease;
  }
  
  .result-textarea:focus {
    border-color: #4CAF50;
    outline: none;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  